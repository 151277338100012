import { observer } from 'mobx-react';
import React, { RefObject } from 'react';

import qs from 'qs';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { UndeliveredSnowflakeFilterContainer } from './UndeliveredSnowflakeFilterContainer';
import { UndeliveredSnowflakeSummaryContainer } from './UndeliveredSnowflakeSummaryContainer';
import { UndeliveredSnowflakeDetailContainer } from './UndeliveredSnowflakeDetailContainer';
import { TabView, TabPanel } from 'primereact/tabview';
import { Flex } from 'reflexbox';
import { Button } from 'primereact/button';
import { SnowflakeExport } from "../snowflake-export/SnowflakeExport";
import { DateHelper } from '../../utils/DateHelper';
import { Growl } from 'primereact/growl';

@observer
export class UndeliveredSnowflakeContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  growl;
  refSummary: RefObject<UndeliveredSnowflakeSummaryContainer> = React.createRef();
  refDetail: RefObject<UndeliveredSnowflakeDetailContainer> = React.createRef();
  refFilter: RefObject<UndeliveredSnowflakeFilterContainer> = React.createRef();
  refExportCheck: RefObject<SnowflakeExport> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
    };
  }

  componentDidMount() {
    const query = (this.props.history.location.search)
      ? qs.parse(
        this.props.history.location.search.substr(1),
      ) as any
      : {} as any;

    if (query) {
      if (query.activeTabIndex) {
        this.setState({ activeTabIndex: parseInt(query.activeTabIndex) });
      }

      const stringToBoolean = (value) => {
        if (value === "true") return true;
        else if (value === "false") return false;
        return value
      }

      if (query.filters) {
        query.filters.startDate = (query.filters.startDate) ? new Date(query.filters.startDate) : "";
        query.filters.endDate = (query.filters.endDate) ? new Date(query.filters.endDate) : "";
        query.filters.isCod = stringToBoolean(query.filters.isCod);
        query.filters.is3PL = stringToBoolean(query.filters.is3PL);
        query.filters.oversla = stringToBoolean(query.filters.oversla);
        query.filters.willOversla = stringToBoolean(query.filters.willOversla);
        query.filters.is1day = stringToBoolean(query.filters.is1day);

        const asyncUpdateFormValuesAndLoad = async () => {
          await this.refFilter.current!.setFormValues(query.filters);
          this.loadData();
        };
        asyncUpdateFormValuesAndLoad();
      }
    }
  }

  loadData() {
    const fParams = this.refFilter.current!.formValues;

    if ((this.state as any).activeTabIndex === 1) {
      this.refDetail.current!.loadData(fParams);
    } else {
      this.refSummary.current!.loadData(fParams);
    }
  }

  downloadCsv() {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

    const fParams = this.refFilter.current!.formValues;
    if ((this.state as any).activeTabIndex === 1) {
      const filterParam = Object.assign({}, fParams);
      if (filterParam.startDate) filterParam.startDate = DateHelper.formatLocalDateForQueryParam(filterParam.startDate);
      if (filterParam.endDate) filterParam.endDate = DateHelper.formatLocalDateForQueryParam(filterParam.endDate);

      filterParam.format = "xls";
      filterParam.user_id = userData.user_id;

      if (this.refExportCheck.current) {
        this.refExportCheck.current.exportExcel('/sf/undelivered/download-v2', filterParam)
      }
    }
  }

  render() {
    return (
      <div>
        <SnowflakeExport ref={this.refExportCheck} />

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div>
          <Flex className="card w-full">
            <Flex className="w-full" column>
              <UndeliveredSnowflakeFilterContainer ref={this.refFilter} />
              <br />
              <Flex>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  onClick={() => this.loadData()}
                />
                {this.state.activeTabIndex === 1 &&
                  <Button
                    className="p-button-primary"
                    label="Export Excel"
                    icon={(this.refExportCheck.current && this.refExportCheck.current.state.loading) ? "pi pi-spin pi-spinner" : ""}
                    disabled={(this.refExportCheck.current && this.refExportCheck.current.state.loading)}
                    onClick={() => this.downloadCsv()}
                  />
                }
              </Flex>
            </Flex>
          </Flex>
        </div>
        <div>
          <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
            <TabPanel header="Summary">
              <UndeliveredSnowflakeSummaryContainer ref={this.refSummary} />
            </TabPanel>
            <TabPanel header="Detail">
              <UndeliveredSnowflakeDetailContainer ref={this.refDetail} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
