import { observer } from "mobx-react";
import moment from "moment";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { DateHelper } from "../../utils/DateHelper";
import { Growl } from "primereact/growl";

@observer
export class UndeliveredSnowflakeDetailContainer extends React.Component<
  any,
  any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  filterParams;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    this.dataSource.onPageChange.subscribe(() => {
      this.loadData(this.filterParams);
    });
  }

  getColumnFieldNames(columnField) {
    const columnFieldNames = {
      awbNumber: "AWB_NUMBER",
      awbDate: "AWB_DATE",
      pengirim: "BILL_CUSTOMER_RDS",
      partnerName: "PARTNER_NAME",
      asal: "ORIGIN_CODE",
      asalKota: "ORIGIN_CITY",
      asalKecamatan: "ORIGIN_DISTRICT",
      tujuan: "DESTINATION_CODE",
      tujuanKota: "DESTINATION_CITY",
      tujuanKecamatan: "DESTINATION_DISTRICT",
      layanan: "SERVICE_TYPE_CODE",
      perwakilan: "REPRESENTATIVE_CODE",
      codNilai: "COD_VALUE",
      lastValidTrackingSiteCode: "LAST_VALID_STATUS_BRANCH_CODE",
      lastTrackingSite: "LAST_VALID_STATUS_BRANCH_NAME",
      lastTrackingSiteCity: "LAST_VALID_STATUS_BRANCH_CITY",
      lastValidTrackingType: "AWB_STATUS_NAME",
      lastValidTrackingDateTime: "AWB_STATUS_DATE_TIME",
      lastProblemTrackingType: "LAST_LPB_PROBLEM_STATUS_AWB_STATUS_NAME",
      lastProblemDateTime: "LAST_LPB_PROBLEM_STATUS_DATE_TIME",
      usiaPaket: "USIA_PAKET",
      SlaMaxDateTimeInternal: "DELIVERY_SLA_DATE",
      overslaDaysInternal: "OVER_SLA_DAYS",
      SlaMaxDateTimeExternal: "DELIVERY_SLA_DATE",
      overslaDaysExternal: "OVER_SLA_DAYS",
    }
    return columnFieldNames[columnField] ? columnFieldNames[columnField] : "";
  }

  loadData(filterParams: any) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortFieldName = this.getColumnFieldNames(this.state.sortField)
    const sortingParams = {
      sortBy: `${sortFieldName}${this.state.sortOrder === -1 ? "-" : ""}`,
    };

    this.filterParams = filterParams;
    const fParams = Object.assign(
      {},
      filterParams,
      pagingParams,
      sortingParams
    );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    this.dataSource.loading = true;
    this.monitoringRestService
      .post("sf/undelivered/list", fParams)
      .subscribe(
        (response) => {
          const mappedData = response.data.map((d) => {
            return {
              ...d,
              awbNumber: d.AWB_NUMBER,
              awbDate: d.AWB_DATE,
              pengirim: d.BILL_CUSTOMER_RDS,
              partnerName: d.PARTNER_NAME,
              asal: d.ORIGIN_CODE,
              asalKota: d.ORIGIN_CITY,
              asalKecamatan: d.ORIGIN_DISTRICT,
              tujuan: d.DESTINATION_CODE,
              tujuanKota: d.DESTINATION_CITY,
              tujuanKecamatan: d.DESTINATION_DISTRICT,
              layanan: d.SERVICE_TYPE_CODE,
              perwakilan: d.REPRESENTATIVE_CODE,
              codNilai: d.COD_VALUE,
              lastValidTrackingSiteCode: d.LAST_VALID_STATUS_BRANCH_CODE,
              lastTrackingSite: d.LAST_VALID_STATUS_BRANCH_NAME,
              lastTrackingSiteCity: d.LAST_VALID_STATUS_BRANCH_CITY,
              lastValidTrackingType: d.AWB_STATUS_NAME,
              lastValidTrackingDateTime: d.AWB_STATUS_DATE_TIME,
              lastProblemTrackingType: d.LAST_LPB_PROBLEM_STATUS_AWB_STATUS_NAME,
              lastProblemDateTime: d.LAST_LPB_PROBLEM_STATUS_DATE_TIME,
              usiaPaket: d.USIA_PAKET,
              SlaMaxDateTimeInternal: d.DELIVERY_SLA_DATE,
              overslaDaysInternal: d.OVER_SLA_DAYS,
              SlaMaxDateTimeExternal: d.DELIVERY_SLA_DATE,
              overslaDaysExternal: d.OVER_SLA_DAYS,
            }
          })
          this.data = {
            data: mappedData,
            count: response.pagination.totalData,
            limit: response.pagination.limit,
            page: response.pagination.page,
          };
          this.populateDataSource();
        },
        (error) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
          .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    await this.loadData(this.filterParams);
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />
        <div className="card">
          <DataTable
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            footer={footer}
          >
            <Column
              field="awbNumber"
              header="No Resi"
              sortable={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={this.columnAwbNumber}
            />
            <Column
              field="awbDate"
              header="Tgl. Transaksi"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="pengirim"
              header="Pengirim"
              sortable={false}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="asal"
              header="Asal"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["asalKota"]} ({rowData["asalKecamatan"]})
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="tujuan"
              header="Tujuan"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["tujuanKota"]} ({rowData["tujuanKecamatan"]})
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="layanan"
              header="Layanan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="perwakilan"
              header="Perwakilan"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="codNilai"
              header="Nilai COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="usiaPaket"
              header="Usia Paket"
              sortable={true}
              style={{ width: "80px", "vertical-align": "text-top" }}
            />
            <Column
              field="overslaDaysInternal"
              header="Over Sla (Internal)"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeInternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : rowData[column.field!];

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="SlaMaxDateTimeInternal"
              header="Tgl. Over Sla (Internal)"
              sortable={false}
              style={{ width: "145px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglValue = moment(rowData[column.field!]);
                let sTglValue: string = "-";
                if (tglValue.isValid()) {
                  sTglValue = tglValue.format("DD-MM-YYYY");
                }

                return (
                  <div>
                    <div>{sTglValue}</div>
                  </div>
                );
              }}
            />
            <Column
              field="overslaDaysExternal"
              header="Over Sla (External)"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeExternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : rowData[column.field!];

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="SlaMaxDateTimeExternal"
              header="Tgl. Over Sla (External)"
              sortable={false}
              style={{ width: "145px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglValue = moment(rowData[column.field!]);
                let sTglValue: string = "-";
                if (tglValue.isValid()) {
                  sTglValue = tglValue.format("DD-MM-YYYY");
                }

                return (
                  <div>
                    <div>{sTglValue}</div>
                  </div>
                );
              }}
            />
            <Column
              field="lastTrackingSite"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastTrackingSiteCity"
              header="Kota Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingType"
              header="Last Status"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingDateTime"
              header="Tgl. Last Status"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />

            <Column
              field="lastProblemTrackingType"
              header="Last Problem"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemDateTime"
              header="Tgl. Last Problem"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
          </DataTable>

          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
