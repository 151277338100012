import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { SelectButton } from 'primereact/selectbutton';
import { observer } from 'mobx-react';
import { Panel } from 'primereact/panel';
import { LAYANAN_OPTIONS } from "../../constants";

export interface IUndeliveredFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  customer?: string;
  partner?: string;
  serviceType?: any;
  asal?: any;
  asalKota?: any;
  asalKecamatan?: any;
  tujuan?: any;
  tujuanKota?: any;
  tujuanKecamatan?: any;
  is3PL?: any;
  isCod?: any;
  oversla?: any;
  willOversla?: any;
  is1day?: any;
  trackingSite?: any;
  lastStatus?: any;
  [key: string]: any;
}

@observer
export class UndeliveredSnowflakeFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IUndeliveredFilterContainerValue = {
    startDate: new Date(),
    endDate: new Date(),
    customer: '',
    partner: '',
    serviceType: '',
    asal: '',
    asalKota: '',
    asalKecamatan: '',
    tujuan: '',
    tujuanKota: '',
    tujuanKecamatan: '',
    is3PL: false,
    isCod: false,
    oversla: '',
    willOversla: '',
    is1day: false,
    trackingSite: '',
    lastStatus: '',
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Ya',
      value: true,
    },
    {
      label: 'Tidak',
      value: false,
    },
  ];

  constructor(props, context) {
    super(props, context);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    if (this.refFormik.current) {
      const newFormValues = Object.assign({}, this.refFormik.current.state.values, values);
      this.refFormik.current!.setValues(newFormValues);
      this.initialFormValues = newFormValues;
      return newFormValues;
    }

    return null;
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <Flex
              wrap
              w={1}>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Periode</label>
                  <div className="col-md-8">
                    <Flex column>
                      <Calendar
                        name="startDate"
                        readOnlyInput={true}
                        dateFormat="yy-mm-dd"
                        showWeek={true}
                        maxDate={formikProps.values.endDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange('startDate')}
                        value={formikProps.values.startDate}
                      />
                      <div className="mt-2 mb-2 text-center">s/d</div>
                      <Calendar
                        name="endDate"
                        readOnlyInput={true}
                        dateFormat="yy-mm-dd"
                        showWeek={true}
                        minDate={formikProps.values.startDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange('endDate')}
                        value={formikProps.values.endDate}
                      />
                    </Flex>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Customer</label>
                  <div className="col-md-8">
                    <InputText
                      name="customer"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.customer}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Partner</label>
                  <div className="col-md-8">
                    <InputText
                      name="partner"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.partner}
                    />
                  </div>
                </div>
              </Flex>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Layanan</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={LAYANAN_OPTIONS}
                      onChange={formikProps.handleChange('serviceType')}
                      value={formikProps.values.serviceType}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">COD</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('isCod')}
                      value={formikProps.values.isCod}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">3PL</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('is3PL')}
                      value={formikProps.values.is3PL}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Over SLA</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('oversla')}
                      value={formikProps.values.oversla}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Akan Over SLA</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('willOversla')}
                      value={formikProps.values.willOversla}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Tak Bergerak 24 Jam</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('is1day')}
                      value={formikProps.values.is1day}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Province Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="province"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.province}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Kota Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="city"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.city}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Gerai Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="trackingSite"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.trackingSite}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Status Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="lastStatus"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.lastStatus}
                    />
                  </div>
                </div>
              </Flex>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-5">Asal</label>
                  <div className="col-md-7">
                    <InputText
                      name="asal"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asal}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Asal (Kota)</label>
                  <div className="col-md-7">
                    <InputText
                      name="asalKota"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asalKota}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Asal (Kecamatan)</label>
                  <div className="col-md-7">
                    <InputText
                      name="asalKecamatan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asalKecamatan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan (Kota)</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuanKota"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuanKota}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan (Kecamatan)</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuanKecamatan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuanKecamatan}
                    />
                  </div>
                </div>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
