import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import React from "react";

import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";

@observer
export class SnowflakeExport extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  statusPending = 0;
  statusActive = 1;
  statusError = 2;
  statusErrorLimit = 3;
  statusDone = 99;

  checkInterval = 5000

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showDialog: false,
    };
  }

  componentDidMount() {}

  get isLoading() {
    return this.state.loading;
  }

  exportExcel(apiUrl: string, params: any) {
    this.setState({ loading: true });

    this.monitoringRestService
      .post(apiUrl, params)
      .subscribe(
        (response) => {
          if ([this.statusPending, this.statusError, this.statusErrorLimit].includes(response.status)) {
            this.setState({ loading: false, showDialog: false });
            let msg = {
              severity: 'error',
              summary: 'Export Excel Gagal',
              detail: response.message
            };
            this.growl.show(msg);
          } else {
            this.checkExport(response.job_export_id)
          }
        },
        () => {
          this.setState({ loading: false });
          let msg = {
            severity: 'error',
            summary: 'Error Excel Gagal',
            detail: 'Terjadi kesalahan pada sistem, coba beberapa saat lagi.'
          };
          this.growl.show(msg);
        },
        () => {
          this.setState({ loading: false });
        },
      );
  }

  checkExport(id: string) {
    this.setState({ loading: true });

    this.monitoringRestService.get(`/job-export/check/${id}`).subscribe(
      (response) => {
        if (response.status === this.statusActive) {
          this.setState({ loading: true, showDialog: true });
          setTimeout(() => {
            this.checkExport(id);
          }, this.checkInterval)
        } else if (response.status === this.statusError) {
          this.setState({ loading: false, showDialog: false });
          let msg = {
            severity: 'error',
            summary: 'Error Excel Gagal',
            detail: response.message
          };
          this.growl.show(msg);
        } else if (response.status === this.statusDone) {
          window.location.href = response.file_url;

          this.setState({ loading: false, showDialog: false });
          let msg = {
            severity: "success",
            summary: "Error Excel Berhasil",
            detail: "Data berhasil didownload.",
          };
          this.growl.show(msg);
        }
      },
      () => {
        this.setState({ loading: false, showDialog: false });
        let msg = {
          severity: 'error',
          summary: 'Error Excel Gagal',
          detail: 'Terjadi kesalahan pada sistem, coba beberapa saat lagi.'
        };
        this.growl.show(msg);
      },
      () => {},
    );
  }

  render() {
    return (
      <>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <Dialog
          header="Informasi"
          visible={this.state.showDialog}
          style={{ width: '40vw' }}
          modal={true}
          onHide={() => this.setState({ showDialog: false })}
          closable={false}
        >
          Export excel dalam proses
        </Dialog>

      </>
       
    );
  }
}
