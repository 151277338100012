import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  IAwbListFilterContainerValue,
  AwbListSnowflakeFilterContainer,
} from "./AwbListSnowflakeFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { SnowflakeExport } from "../snowflake-export/SnowflakeExport";

export interface IAwbListContainerQuery {
  filters: IAwbListFilterContainerValue;
}

@observer
export class AwbListSnowflakeContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<AwbListSnowflakeFilterContainer> = React.createRef();
  refExportCheck: RefObject<SnowflakeExport> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? (qs.parse(this.props.history.location.search.substr(1)) as any)
      : ({} as any);

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
  }

  getColumnFieldNames(columnField) {
    const columnFieldNames = {
      awbNumber: "AWB_NUMBER",
      awbDate: "AWB_DATE",
      tglFoto: "MANIFEST_DATE_TIME",
      tglInput: "MANIFEST_DATE_TIME",
      pengirim: "BILL_CUSTOMER_RDS",
      partnerName: "PARTNER_NAME",
      reseller: "REF_RESELLER",
      noHpReseller: "REF_RESELLER_PHONE",
      asal: "ORIGIN_CODE",
      asalKota: "ORIGIN_CITY",
      asalKecamatan: "ORIGIN_DISTRICT",
      perwakilan: "REPRESENTATIVE_CODE",
      tujuan: "DESTINATION_CODE",
      tujuanKota: "DESTINATION_CITY",
      tujuanKecamatan: "DESTINATION_DISTRICT",
      address: "CONSIGNEE_ADDRESS",
      zonaTujuan: "TO_ZONE_CODE",
      layanan: "SERVICE_TYPE_CODE",
      promoCode: "NEWRDS_PROMO",
      penerima: "CONSIGNEE_NAME",
      noHpPenerima: "CONSIGNEE_PHONE",
      koli: "PARCEL_QTY",
      berat: "WEIGHT",
      beratAsli: "WEIGHT_REAL",
      beratVolume: "WEIGHT_VOLUME",
      ketVolume: "KETVOLUME",
      codBiaya: "COD_FEE",
      codNilai: "COD_VALUE",
      biaya: "STT_BIAYA",
      totalBiaya: "STT_TOTALBIAYA",
      partner3PlAwbNumber: "XXXXXXXXXXXXXXXXXXXX",
      noKonfirmasi: "REFERENCE_NUMBER",
      leadTime: "LEAD_TIME",
      estLeadTimePartner: "EST_LEAD_TIME",
      slaMax: "LEAD_TIME_MAX_DAYS",
      slaStageStatusInternal: "SLA_STAGE_STATUS",
      slaStatusInternal: "SLA_STATUS",
      overslaDaysInternal: "OVER_SLA_DAYS",
      SlaMaxDateTimeInternal: "DELIVERY_SLA_DATE",
      overslaDaysExternal: "OVER_SLA_DAYS",
      SlaMaxDateTimeExternal: "DELIVERY_SLA_DATE",
      overEstSlaDays: "OVER_EST_SLA_DAYS",
      estSlaMaxDate: "ESTIMATE_DELIVERY_SLA_DATE",
      usiaPaket: "USIA_PAKET",
      deliveryDate: "FIRST_DELIVERED_DATE_TIME",
      receiverName: "RECEIVER_NAME",
      receiverRemark: "RECEIVERREMARK",
      lastValidTrackingType: "AWB_STATUS_NAME",
      lastValidTrackingDateTime: "AWB_STATUS_DATE_TIME",
      prevFinalTrackingType: "PREV_LAST_VALID_STATUS_AWB_STATUS_NAME",
      prevFinalTrackingDateTime: "PREV_LAST_VALID_STATUS_DATE_TIME",
      lastValidTrackingTypePublic: "LAST_LPB_PUBLIC_STATUS_AWB_STATUS_NAME",
      lastValidTrackingTypePublicDateTime: "LAST_LPB_PUBLIC_STATUS_DATE_TIME",
      lastProblemTrackingType: "LAST_LPB_PROBLEM_STATUS_AWB_STATUS_NAME",
      lastProblemDateTime: "LAST_LPB_PROBLEM_STATUS_DATE_TIME",
      lastValidTrackingSiteCode: "LAST_VALID_STATUS_BRANCH_CODE",
      manifestTrackingSiteName: "BRANCH_NAME",
      lastValidTrackingSiteName: "LAST_VALID_STATUS_BRANCH_NAME",
      lastValidTrackingSiteCity: "LAST_VALID_STATUS_BRANCH_CITY",
      lastValidNoteInternal: "LAST_VALID_STATUS_NOTE",
      isCod: "PAYMENTMETHOD",
      isCutOff: "IS_CUT_OFF",
      prtHsCode: "PRTHSCODE",
      isReturn: "ISRETURN",
      prtIsFreeOngkir: "FREE_ONGKIR",
      prtTransactionType: "IS_FREEONGKIR",
      prtClaimValue: "CLAIM_VALUE",
      tags: "XXXXXXXXXXXXXXXXXXXX",
      partner3PlName: "HANDOVER_3PL_NAME",
    }
    return columnFieldNames[columnField] ? columnFieldNames[columnField] : "";
  }

  fetchData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };

    const sortFieldName = this.getColumnFieldNames(this.state.sortField)
    const sortingParams = {
      sortBy: `${sortFieldName}${this.state.sortOrder === -1 ? "-" : ""}`,
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.period) {
      fParams.startDate = moment(fParams.period[0]).format("YYYY-MM-DD");
      if (fParams.period[1] !== null) {
        fParams.endDate = moment(fParams.period[1]).format("YYYY-MM-DD");
      } else {
        fParams.endDate = moment(fParams.period[0]).format("YYYY-MM-DD");
      }
    }
    delete fParams.period;

    fParams.format = "list";
    fParams.fParameters = JSON.stringify(fParams);
    this.dataSource.loading = true;
    this.setState({ activeTabIndex: 0 });
    return this.monitoringRestService.post("sf/lpb/list", fParams)
  }
  
  loadData(filterParams: any = null) {
    this.fetchData(filterParams).subscribe(
      (response) => {
        const mappedData = response.data.map((d) => {
          return {
            ...d,
            awbNumber: d.AWB_NUMBER,
            awbDate: d.AWB_DATE,
            tglFoto: d.MANIFEST_DATE_TIME,
            tglInput: d.MANIFEST_DATE_TIME,
            pengirim: d.BILL_CUSTOMER_RDS,
            partnerName: d.PARTNER_NAME,
            reseller: d.REF_RESELLER,
            noHpReseller: d.REF_RESELLER_PHONE,
            asal: d.ORIGIN_CODE,
            asalKota: d.ORIGIN_CITY,
            asalKecamatan: d.ORIGIN_DISTRICT,
            perwakilan: d.REPRESENTATIVE_CODE,
            tujuan: d.DESTINATION_CODE,
            tujuanKota: d.DESTINATION_CITY,
            tujuanKecamatan: d.DESTINATION_DISTRICT,
            address: d.CONSIGNEE_ADDRESS,
            zonaTujuan: d.TO_ZONE_CODE,
            layanan: d.SERVICE_TYPE_CODE,
            promoCode: d.NEWRDS_PROMO,
            penerima: d.CONSIGNEE_NAME,
            noHpPenerima: d.CONSIGNEE_PHONE,
            koli: d.PARCEL_QTY,
            berat: d.WEIGHT,
            beratAsli: d.WEIGHT_REAL,
            beratVolume: d.WEIGHT_VOLUME,
            ketVolume: d.KETVOLUME,
            codBiaya: d.COD_FEE,
            codNilai: d.COD_VALUE,
            biaya: d.STT_BIAYA,
            totalBiaya: d.STT_TOTALBIAYA,
            partner3PlAwbNumber: d.XXXXXXXXXXXXXXXXXXXX,
            noKonfirmasi: d.REFERENCE_NUMBER,
            leadTime: d.LEAD_TIME,
            estLeadTimePartner: d.EST_LEAD_TIME,
            slaMax: d.LEAD_TIME_MAX_DAYS,
            slaStageStatusInternal: d.SLA_STAGE_STATUS,
            slaStatusInternal: d.SLA_STATUS,
            overslaDaysInternal: d.OVER_SLA_DAYS,
            SlaMaxDateTimeInternal: d.DELIVERY_SLA_DATE,
            overslaDaysExternal: d.OVER_SLA_DAYS,
            SlaMaxDateTimeExternal: d.DELIVERY_SLA_DATE,
            overEstSlaDays: d.OVER_EST_SLA_DAYS,
            estSlaMaxDate: d.ESTIMATE_DELIVERY_SLA_DATE,
            usiaPaket: d.USIA_PAKET,
            deliveryDate: d.FIRST_DELIVERED_DATE_TIME,
            receiverName: d.RECEIVER_NAME,
            receiverRemark: d.RECEIVERREMARK,
            lastValidTrackingType: d.AWB_STATUS_NAME,
            lastValidTrackingDateTime: d.AWB_STATUS_DATE_TIME,
            prevFinalTrackingType: d.PREV_LAST_VALID_STATUS_AWB_STATUS_NAME,
            prevFinalTrackingDateTime: d.PREV_LAST_VALID_STATUS_DATE_TIME,
            lastValidTrackingTypePublic: d.LAST_LPB_PUBLIC_STATUS_AWB_STATUS_NAME,
            lastValidTrackingTypePublicDateTime: d.LAST_LPB_PUBLIC_STATUS_DATE_TIME,
            lastProblemTrackingType: d.LAST_LPB_PROBLEM_STATUS_AWB_STATUS_NAME,
            lastProblemDateTime: d.LAST_LPB_PROBLEM_STATUS_DATE_TIME,
            lastValidTrackingSiteCode: d.LAST_VALID_STATUS_BRANCH_CODE,
            manifestTrackingSiteName: d.BRANCH_NAME,
            lastValidTrackingSiteName: d.LAST_VALID_STATUS_BRANCH_NAME,
            lastValidTrackingSiteCity: d.LAST_VALID_STATUS_BRANCH_CITY,
            lastValidNoteInternal: d.LAST_VALID_STATUS_NOTE,
            isCod: d.PAYMENTMETHOD,
            isCutOff: d.IS_CUT_OFF,
            prtHsCode: d.PRTHSCODE,
            isReturn: d.ISRETURN,
            prtIsFreeOngkir: d.FREE_ONGKIR,
            prtTransactionType: d.IS_FREEONGKIR,
            prtClaimValue: d.CLAIM_VALUE,
            tags: d.XXXXXXXXXXXXXXXXXXXX,
            partner3PlName: d.HANDOVER_3PL_NAME,
          }
        })
        this.data = {
          data: mappedData,
          count: response.pagination.totalData,
          limit: response.pagination.limit,
          page: response.pagination.page,
        };
        this.populateDataSource();
      },
      (err) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }
  
  downloadCsv(filterParams: any = null) {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

      const fParams = filterParams
        ? Object.assign({}, filterParams)
        : Object.assign({}, this.refFilter.current!.formValues);

      if (fParams.period) {
        fParams.startDate = moment(fParams.period[0]).format("YYYY-MM-DD");
        if (fParams.period[1] !== null) {
          fParams.endDate = moment(fParams.period[1]).format("YYYY-MM-DD");
        } else {
          fParams.endDate = moment(fParams.period[0]).format("YYYY-MM-DD");
        }
      }
      delete fParams.period;

      fParams.fParameters = JSON.stringify(fParams);
      fParams.format = "xls";
      fParams.user_id = userData.user_id;
      if (this.refExportCheck.current) {
        this.refExportCheck.current.exportExcel('/sf/lpb/download-v2', fParams)
      }
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <SnowflakeExport ref={this.refExportCheck} />

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <AwbListSnowflakeFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary"
                label="Export Excel"
                icon={
                  this.refExportCheck.current && this.refExportCheck.current.state.loading ? "pi pi-spin pi-spinner" : ""
                }
                disabled={this.refExportCheck.current && this.refExportCheck.current.state.loading}
                onClick={() => this.downloadCsv()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            footer={footer}
          >
            <Column
              field="awbNumber"
              header="No Resi"
              sortable={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={this.columnAwbNumber}
            />
            <Column
              field="awbDate"
              header="Tgl. Transaksi"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglFoto"
              header="Tgl. Foto"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglInput"
              header="Tgl. Input"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglFoto"
              header="Tgl. Manifest"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="pengirim"
              header="Pengirim"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="partnerName"
              header="Partner Name"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="reseller"
              header="Reseller"
              sortable={false}
              style={{ width: "150px", "vertical-align": "text-top" }}
            />
            <Column
              field="noHpReseller"
              header="Telp Reseller"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="asal"
              header="Asal Code"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const asal = rowData[column.field!] ? rowData[column.field!] : "";
                const subData = asal.substring(0, 3)
                return (
                  <div>
                    <div>
                      <b>{subData}</b>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="asal"
              header="Asal"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["asalKota"]} {rowData["asalKecamatan"] ? `(${rowData["asalKecamatan"]})` : ""}
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="perwakilan"
              header="Perwakilan"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="tujuan"
              header="Tujuan Code"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tujuan = rowData[column.field!] ? rowData[column.field!] : ""
                const subData = tujuan.substring(0, 3);
                return (
                  <div>
                    <div>
                      <b>{subData}</b>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="tujuan"
              header="Tujuan"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["tujuanKota"]} {rowData["tujuanKecamatan"] ? `(${rowData["tujuanKecamatan"]})` : ""}
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="address"
              header="Alamat"
              sortable={true}
              style={{ width: "150px", "vertical-align": "text-top",overflow:'auto' }}
            />
            <Column
              field="zonaTujuan"
              header="Zona Tujuan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="layanan"
              header="Layanan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="promoCode"
              header="Kode Promo"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="penerima"
              header="Penerima"
              sortable={false}
              style={{ width: "150px", "vertical-align": "text-top" }}
            />
            <Column
              field="noHpPenerima"
              header="Telp Penerima"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="koli"
              header="Koli"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="berat"
              header="Berat"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="beratAsli"
              header="Berat Asli"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="beratVolume"
              header="Berat Volume"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="ketVolume"
              header="Ket. Volume"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="codBiaya"
              header="Biaya COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="codNilai"
              header="Nilai COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="biaya"
              header="Biaya"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue ? colValue.toLocaleString() : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="totalBiaya"
              header="Total Biaya"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue ? colValue.toLocaleString() : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="partner3PlAwbNumber"
              header="Resi Pihak Ketiga"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="noKonfirmasi"
              header="No Konfirmasi"
              sortable={true}
              style={{ width: "240px", "vertical-align": "text-top" }}
            />
            <Column
              field="leadTime"
              header="Lead Time"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="estLeadTimePartner"
              header="Est Lead Time Partner"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="slaMax"
              header="SLA Maksimal"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="slaStatusInternal"
              header="Status SLA"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="overslaDaysInternal"
              header="Over SLA (Internal)"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeInternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="overslaDaysExternal"
              header="Over SLA (External)"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeExternal"]);
                let sTglOverSla: string = "-";

                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="overEstSlaDays"
              header="Est Lead Over SLA Partner"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const estTglOverSla = moment(rowData["estSlaMaxDate"]);
                let estsTglOverSla: string = "-";

                if (estTglOverSla.isValid()) {
                  estsTglOverSla = estTglOverSla.format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{estsTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="usiaPaket"
              header="Usia Paket"
              sortable={true}
              style={{ width: "80px", "vertical-align": "text-top" }}
            />
            <Column
              field="deliveryDate"
              header="Tgl. Terima Barang"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={this.columnDateFormat}
            />
            <Column
              field="receiverName"
              header="Nama Penerima Barang"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="receiverRemark"
              header="Remark Penerima Barang"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingType"
              header="Last Status"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingDateTime"
              header="Tgl. Last Status"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="prevFinalTrackingType"
              header="Last Status (2)"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="prevFinalTrackingDateTime"
              header="Tgl. Last Status (2)"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingTypePublic"
              header="Final Status Public"
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingTypePublicDateTime"
              header="Tgl. Final Status Public"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemTrackingType"
              header="Last Problem"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemDateTime"
              header="Tgl. Last Problem"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteCode"
              header="Kode Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="manifestTrackingSiteName"
              header="Gerai Manifest"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteName"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteCity"
              header="Kota Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidNoteInternal"
              header="Catatan"
              sortable={false}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="isCod"
              header="Cod"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />

            <Column
              field="isCutOff"
              header="Cut Off"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtHsCode"
              header="HS Code"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="isReturn"
              header="Return"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtIsFreeOngkir"
              header="Bebas Ongkir"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtTransactionType"
              header="Transaction Type"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtClaimValue"
              header="Claim Value"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="tags"
              header="Tags"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const returnData = !!rowData[column.field!] ? rowData[column.field!].length > 0 ? rowData[column.field!].join(", ") : '' : '';
                
                return (
                  <div>
                    <div>
                      {returnData}
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="partner3PlName"
              header="Partner 3PL Name"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
          </DataTable>

          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
       
      </div>
    );
  }
}
