import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import { UndeliveredSnowflakeSummaryByTujuanContainer } from './UndeliveredSnowflakeSummaryByTujuanContainer';
import { UndeliveredSnowflakeSummaryByPartnerContainer } from './UndeliveredSnowflakeSummaryByPartnerContainer';
import { UndeliveredSnowflakeSummaryByLastStatusContainer } from './UndeliveredSnowflakeSummaryByLastStatusContainer';
import { Fieldset } from 'primereact/fieldset';
import { UndeliveredSnowflakeSummaryByLastTrackingSiteContainer } from './UndeliveredSnowflakeSummaryByLastTrackingSiteContainer';

@observer
export class UndeliveredSnowflakeSummaryContainer extends React.Component<any, any> {
  filterParams;
  refTujuan: RefObject<UndeliveredSnowflakeSummaryByTujuanContainer> = React.createRef();
  refPartner: RefObject<UndeliveredSnowflakeSummaryByPartnerContainer> = React.createRef();
  refLastTrackingSite: RefObject<UndeliveredSnowflakeSummaryByLastTrackingSiteContainer> = React.createRef();
  refLastStatus: RefObject<UndeliveredSnowflakeSummaryByLastStatusContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      tujuanCollapsed: false,
      partnerCollapsed: false,
      lastTrackingSiteCollapsed: false,
      lastStatusCollapsed: false,
    };
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData();
    // });
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    this.refTujuan.current!.loadData(this.filterParams);
    this.refPartner.current!.loadData(this.filterParams);
    this.refLastTrackingSite.current!.loadData(this.filterParams);
    this.refLastStatus.current!.loadData(this.filterParams);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <Fieldset legend="Tujuan" toggleable={true} collapsed={this.state.tujuanCollapsed} onToggle={(e) => this.setState({ tujuanCollapsed: e.value })}>
              <UndeliveredSnowflakeSummaryByTujuanContainer ref={this.refTujuan} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Gerai Terakhir" toggleable={true} collapsed={this.state.lastTrackingSiteCollapsed} onToggle={(e) => this.setState({ lastTrackingSiteCollapsed: e.value })}>
              <UndeliveredSnowflakeSummaryByLastTrackingSiteContainer ref={this.refLastTrackingSite} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Status Terakhir" toggleable={true} collapsed={this.state.lastStatusCollapsed} onToggle={(e) => this.setState({ lastStatusCollapsed: e.value })}>
              <UndeliveredSnowflakeSummaryByLastStatusContainer ref={this.refLastStatus} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Partner" toggleable={true} collapsed={this.state.partnerCollapsed} onToggle={(e) => this.setState({ partnerCollapsed: e.value })}>
              <UndeliveredSnowflakeSummaryByPartnerContainer ref={this.refPartner} />
            </Fieldset>
          </div>
        </div>
      </div>
    );
  }
}
